
const QuestionsData = [
  {
    id: 1,
    title: 'What is the capital of India ?',
    description: 'Hint: It is in North India',
  },
  {
    id: 2,
    title: 'What is React.js ?',
    description: 'Hint: It is related to Software development',
  },
  {
    id: 3,
    title: 'Which is the largest building in the world ?',
    description: 'Hint: It is in Dubai',
  },
]

export default QuestionsData